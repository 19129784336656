import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./SurveyDialog.css";
import dialogTheme from "../dialogTheme";

const SurveyDialog = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const surveyUrlEn = process.env.REACT_APP_surveyUrlEn?.toString().replace(/{AND}/g,"&");
  const surveyUrlFr = process.env.REACT_APP_surveyUrlFr?.toString().replace(/{AND}/g,"&");
  const surveySessionKey = 'forumSearchSurvey';

  const getLinkUrl = () => {
      return i18n.resolvedLanguage === 'en'
      ? surveyUrlEn
      : surveyUrlFr;
  };

  useEffect(() => {
    showSurvey();
    // eslint-disable-next-line
  },[]);

  const setSurveySession = () => {
    const expiry = new Date();
    expiry.setDate(expiry.getDate() + 30);
    const item = {
      value: new Date().getTime(),
      expiry: expiry.getTime() };
    localStorage.setItem( surveySessionKey, JSON.stringify(item) );
  }

  const getSurveySession = () => {
    const data = localStorage.getItem(surveySessionKey);
    if (!data) {
      return null;
    }
    const item = JSON.parse(data);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(surveySessionKey);
      return null;
    }
    return item.value;
  }


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const declineSurvey = () => {
    handleClose();
  };
  const acceptSurvey = () => {
    handleClose();
  };

  const showSurvey = () => {
    if (!getSurveySession()) {
      setSurveySession();
      handleOpen();
    }
  };


  return (
    <div>
      <ThemeProvider theme={dialogTheme}>
        <Dialog
          open={open}
          onClose={handleClose}
          className={i18n.language === 'fr' ? 'french' : 'english'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              fontFamily: "Muli, sans-serif",
              fontSize: i18n.language === 'fr' ? '18.4px' : '14pt',
              color: "white",
              overflowY: "clip"
            },
          }}
        >
          <div
            className="modal-content"
            role="dialog"
            tabIndex={-1}
            aria-modal="true"
            aria-label="Tell us about your experience!"
          >
            <DialogTitle id="alert-dialog-title">
            <div>
              <div className="x-button-container">
              <a
              href="#!"
              rel="noopener noreferrer"
              onClick={declineSurvey}
              aria-label="Close"
              id="DistributionPopUpXButtonLink"
              >
				<div className="DistributionPopUpXButton" onClick={declineSurvey}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path d="M7 7L17 17M7 17L17 7" stroke="#fff" stroke-width="1" stroke-linecap="round"/>
					</svg>
				</div>
			</a>
            </div>
                <div className="logo-container">
                  <img
                    src="/assets/b2c/images/wsib_logo_white.svg"
                    alt="Workplace Safety and Insurance Board"
                    id="WSIBLogo"
                  />
                  <img
                    src="/assets/b2c/images/forum_logo_white.svg"
                    alt="Forum Research"
                    id="ForumResearchLogo"
                  />
                </div>
              </div>
            </DialogTitle>
           
            <Typography component={"h1"} className="DistributionPopUpTitle">{t("survey.tell_us")}</Typography>
            
                
                  <p className="DistributionPopUpText">
                    {t("survey.thank_you1")}
                  </p>
                  
            
              
            
            <DialogActions style={{padding: "0px"}}>
              <div className="DistributionPopUpBottom">
                <a
                  href={getLinkUrl()}
                  tabIndex={0}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={acceptSurvey}
                  className="DistributionPopUpLink"
                  id="DistributionPopUpYes"
                  title={t("feedbackTitle")} 
                  aria-label={t("feedbackTitle")}
                >
                  <div className="DistributionPopUpButton">
                    <span>
                      {t("survey.yes_feedback")}
                    </span>
                  </div>
                </a>
                <a
                  href="#!"
                  onClick={declineSurvey}
                  className="DistributionPopUpLink"
                  id="DistributionPopUpNo"
                >
                  <div className="DistributionPopUpButton2">
                    <span>
                      {t("survey.no_feedback")}
                    </span>
                  </div>
                </a>
                <div>
                <p className="DistributionPopUpText2">
                    {t("survey.thank_you2")}
                  </p>
                  </div>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};
export default SurveyDialog;
