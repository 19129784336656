// sign in reqeust from angular project:
// https://wsibdev1.b2clogin.com/wsibdev1.onmicrosoft.com/b2c_1_udu_signinup/oauth2/v2.0/authorize?client_id=35c96939-2e90-4242-9f43-a32051fbb40a&redirect_uri=http://localhost:4200/auth-callback&response_type=code&scope=openid offline_access https://wsibdev1.onmicrosoft.com/uduapi/UDU_API_ACCESS&state=3a4f70138fa04e0fb34cd3204b09c2d8&code_challenge=VP82Crurz2dHIfFCJGNj8JTUjmUiupjuXClZ0kdvPJs&code_challenge_method=S256&response_mode=query

import { Configuration, PopupRequest } from "@azure/msal-browser";

export const msalConfig: Record<string, Configuration> = {
  signin: {
    auth: {
      clientId: process.env.REACT_APP_clientId!,
      authority: process.env.REACT_APP_authority,
      knownAuthorities: [process.env.REACT_APP_knownAuthority!],
      redirectUri: process.env.REACT_APP_redirectUrl,
      postLogoutRedirectUri: "https://www.wsib.ca/en",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  signup: {
    auth: {
      clientId: process.env.REACT_APP_clientId!,
      authority: process.env.REACT_APP_authority_signup,
      knownAuthorities: [process.env.REACT_APP_knownAuthority!],
      redirectUri: process.env.REACT_APP_redirectUrl,
      postLogoutRedirectUri: "https://www.wsib.ca/en",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
};

const url = window.location.href.toLowerCase();
if (url.indexOf('lang=en') !== -1) {
  window.sessionStorage.setItem('lang', 'en');
} else if (url.indexOf('lang=fr') !== -1) {
  window.sessionStorage.setItem('lang', 'fr');
} 

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["openid", "offline_access", process.env.REACT_APP_Scope!],
  extraQueryParameters: {ui_locales: window.sessionStorage.getItem('lang')!},
};
